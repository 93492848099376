var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"title"},[_vm._v(" Gérez votre équipe ")]),_c('b-modal',{attrs:{"width":640,"active":_vm.hasInviteModalOpen,"can-cancel":false},on:{"update:active":function($event){_vm.hasInviteModalOpen=$event},"close":function($event){_vm.reactivatedUser = null}}},[(_vm.hasInviteModalOpen)?_c('BuySeatForm',{attrs:{"user":_vm.reactivatedUser},on:{"cancel":function($event){_vm.hasInviteModalOpen = false; _vm.reactivatedUser = null;},"done":_vm.onInvited}}):_vm._e()],1),_c('section',[_c('nav',{staticClass:"is-flex is-justify-content-space-between"},[_c('h2',{staticClass:"title is-4"},[_vm._v(" Invitations en attente "),(!_vm.isFetchingUsers)?[_vm._v(" ("+_vm._s(_vm.invites.length)+") ")]:_vm._e()],2),_c('b-button',{attrs:{"type":"is-primary","icon-left":"user-plus"},on:{"click":function($event){_vm.hasInviteModalOpen = true}}},[_vm._v(" Inviter un membre ")])],1),(_vm.isFetchingUsers)?_c('b-skeleton',{attrs:{"height":"200","active":""}}):_c('div',{ref:"box",staticClass:"box is-relative mxw-full"},[_c('b-table',{attrs:{"data":_vm.invites},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-centered"},[_vm._v(" Il n'y a aucune invitation en attente. ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Nom","field":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_c('strong',[_vm._v(" "+_vm._s(row.firstname)+" "+_vm._s(row.lastname)+" ")])]),_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(row.email)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Rôle","field":"role","cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-tooltip',{attrs:{"multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:(("role." + (row.role))),expression:"`role.${row.role}`"}]})]},proxy:true}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("roles." + (row.role))),expression:"`roles.${row.role}`"}]})])]}}])}),_c('b-table-column',{attrs:{"label":"Invité","field":"created_at","cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"mt-1"},[_c('b-tooltip',{attrs:{"label":_vm.$moment.utc(row.created_at).local().format('DD/MM/YY [à] HH:mm')}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.created_at),'from'))+" ")])],1)]}}])}),_c('b-table-column',{attrs:{"cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-dropdown',{attrs:{"position":"is-top-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-text","icon-left":"ellipsis-h"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{on:{"click":function($event){return _vm.resendInvite(row)}}},[_vm._v(" Renvoyer le mail d'invitation ")]),_c('b-dropdown-item',{on:{"click":function($event){_vm.editedInvite = row}}},[_vm._v(" Modifier ")]),_c('b-dropdown-item',{staticClass:"has-text-danger",on:{"click":function($event){return _vm.deleteInvite(row)}}},[_vm._v(" Supprimer ")])],1)]}}])})],1)],1)],1),_c('section',{staticClass:"mt-5"},[_c('h2',{staticClass:"title is-4"},[_vm._v(" Membres "),(!_vm.isFetchingUsers)?[_vm._v(" ("+_vm._s(_vm.users.length)+") ")]:_vm._e()],2),(_vm.isFetchingUsers)?_c('b-skeleton',{attrs:{"height":"200","active":""}}):_c('div',{ref:"box",staticClass:"box is-relative mxw-full"},[_c('b-table',{attrs:{"data":_vm.users},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-centered"},[_vm._v(" Il n'y a aucune invitation en attente. ")])]},proxy:true}])},[_c('b-table-column',{attrs:{"label":"Nom","field":"firstname"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',[_c('strong',[_vm._v(" "+_vm._s(row.firstname)+" "+_vm._s(row.lastname)+" ")])]),_c('p',{staticClass:"mt-2"},[_vm._v(" "+_vm._s(row.email)+" ")])]}}])}),_c('b-table-column',{attrs:{"label":"Rôle","field":"role","cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('b-tooltip',{attrs:{"multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('span',{directives:[{name:"t",rawName:"v-t",value:(("role." + (row.role))),expression:"`role.${row.role}`"}]})]},proxy:true}],null,true)},[_c('span',{directives:[{name:"t",rawName:"v-t",value:(("roles." + (row.role))),expression:"`roles.${row.role}`"}]})])]}}])}),_c('b-table-column',{attrs:{"label":"Statut","field":"status","cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('span',{directives:[{name:"t",rawName:"v-t",value:(("user.status." + (row.status))),expression:"`user.status.${row.status}`"}],class:("tag is-medium " + (_vm.$options.static.statusClass[row.status]))})]}}])}),_c('b-table-column',{attrs:{"label":"Connecté","field":"last_login_at","cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.last_login_at)?_c('p',{staticClass:"mt-1"},[_c('b-tooltip',{attrs:{"label":_vm.$moment.utc(row.last_login_at).local().format('DD/MM/YY [à] HH:mm')}},[_vm._v(" "+_vm._s(_vm._f("moment")(_vm._f("momentFromUTC")(row.last_login_at),'from'))+" ")])],1):_c('p',{staticClass:"mt-1 has-text-weight-bold"},[_vm._v(" Jamais connecté ")])]}}])}),(_vm.$store.getters['auth/permissions'].canManageUsers)?_c('b-table-column',{attrs:{"cell-class":"valign-middle"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.role != 'OWNER' && row.uuid != _vm.authUser.uuid)?_c('b-dropdown',{attrs:{"position":"is-top-left"},scopedSlots:_vm._u([{key:"trigger",fn:function(){return [_c('b-button',{attrs:{"type":"is-text","icon-left":"ellipsis-h"}})]},proxy:true}],null,true)},[(row.status == 'ACTIVE')?_c('b-dropdown-item',{on:{"click":function($event){_vm.editedUser = row}}},[_vm._v(" Modifier le rôle ")]):_vm._e(),(row.status == 'ACTIVE')?_c('b-dropdown-item',{on:{"click":function($event){_vm.switchedUser = row}}},[_vm._v(" Remplacer ")]):_vm._e(),(row.status == 'SUSPENDED')?_c('b-dropdown-item',{on:{"click":function($event){return _vm.reactivateUser(row)}}},[_vm._v(" Réactiver ")]):_vm._e(),_c('b-dropdown-item',{staticClass:"has-text-danger",on:{"click":function($event){return _vm.deleteUser(row)}}},[_vm._v(" Supprimer ")])],1):_vm._e()]}}],null,false,3355772937)}):_vm._e()],1)],1)],1),(_vm.editedInvite)?_c('b-modal',{attrs:{"width":480,"active":""},on:{"close":function($event){_vm.editedInvite = null}}},[_c('UserInviterEdit',{attrs:{"userInvite":_vm.editedInvite},on:{"done":_vm.onInviteUpdated}})],1):_vm._e(),(_vm.editedUser)?_c('b-modal',{attrs:{"width":480,"active":""},on:{"close":function($event){_vm.editedUser = null}}},[_c('UserEdit',{attrs:{"user":_vm.editedUser},on:{"done":function($event){return _vm.onUserUpdated('editedUser', $event)}}})],1):_vm._e(),(_vm.switchedUser)?_c('b-modal',{attrs:{"width":480,"active":""},on:{"close":function($event){_vm.switchedUser = null}}},[_c('UserSwitch',{attrs:{"user":_vm.switchedUser},on:{"done":function($event){return _vm.onUserUpdated('switchedUser', $event)}}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }